import * as React from "react";
import { useState, useEffect } from "react";
import classNames from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { createStyles } from "@material-ui/core";
import { connect } from "react-redux";

// @material-ui/core components
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  Tooltip,
} from "@material-ui/core";

// @material-ui/icons
import { LaptopMac, Menu, Apps } from "@material-ui/icons";

import { ProdInfo, ColorType } from "assets/jss/views/PhoenixCommons";
import { sMyStudy } from "assets/jss/views/ResStrings";

import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";
const styles = createStyles(headerStyle as any);

type ChangeColorOnScrollType = {
  height: number;
  color: ColorType;
};

interface Props extends WithStyles<typeof styles> {
  user: any;
  color: ColorType;
  links: React.ReactNode;
  // this will cause the header to change the color from props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to props.color (see above)
  changeColorOnScroll: ChangeColorOnScrollType;
  route: any;
  history: any;
  classes: any;
}

const HeaderBase: React.FC<Props> = ({
  user,
  // component props
  classes,
  color,
  links,
  changeColorOnScroll,
  route,
  history,
}: Props) => {
  const [s_mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setMobileOpen(open);
  };

  useEffect(() => {
    if (route.home && changeColorOnScroll && window) {
      window.addEventListener("scroll", headerColorChange);
    }

    if (route.clsrm) setHeaderColor(false);

    return () => {
      if (route.home && changeColorOnScroll && window) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  }, []);

  const setHeaderColor = (transparent: boolean) => {
    if (transparent) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    }
  };

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (!route.clsrm) {
      setHeaderColor(windowsScrollTop <= changeColorOnScroll.height);
    }
  };

  // const renderCartIcon = () => {
  //   if (!user) { return ""; }

  //   const numItems = cart !== null ? cart.items.length : 0;
  //   const numBadge = numItems !== 0
  //       ? <Badge badgeContent={numItems} color="error" className={classes.margin}><ShoppingCart /></Badge>
  //       : <ShoppingCart />;
  //   return
  //     <Tooltip title={"Shopping Cart"} placement="bottom">
  //       <IconButton color="inherit" onClick={() => history.push("/shp/cart")}>{numBadge}</IconButton>
  //     </Tooltip>
  // };

  const renderCourses = (
    <Tooltip title="Courses" placement="bottom">
      <IconButton color="inherit" onClick={() => history.push(`/cr_list`)}>
        <Apps />
      </IconButton>
    </Tooltip>
  );

  const renderMyStudy = (
    <Tooltip title={sMyStudy} placement="bottom">
      <IconButton color="inherit" onClick={() => history.push(`/mys`)}>
        <LaptopMac />
      </IconButton>
    </Tooltip>
  );

  const getBarClasses = () => {
    var appBarClasses;
    if (route.show === false) {
      appBarClasses = classes.appBarHide;
    } else if (route.clsrm === true) {
      appBarClasses = classNames({
        [classes.appBarClassroom]: true,
        [classes[color]]: color,
        [classes.fixed]: true,
      });
    } else {
      appBarClasses = route.home
        ? classNames({
            [classes.appBar]: true,
            [classes[color]]: color,
            [classes.fixed]: true,
          })
        : classNames({
            [classes.appBar]: true,
            [classes[changeColorOnScroll.color]]: color,
            [classes.fixed]: true,
          });
    }
    return appBarClasses;
  };

  // Apricot: added brandComponent
  const brandComponent = (
    <Tooltip title="Home" placement="bottom">
      <Button
        className={classes.title}
        style={{ fontWeight: 300 }}
        onClick={() => history.push("/")}>
        <img
          src={require("assets/svg/apricot.svg")}
          className={classes.logo}
          alt={"Home"}
        />
        <div className={route.clsrm ? classes.showFor1023Down : ""}>
          <span>{ProdInfo.Name}</span>
        </div>
      </Button>
    </Tooltip>
  );

  const HeadLinks: any = links;

  const hamburgerBtn = (
    <Tooltip title="Menu" placement="bottom">
      <IconButton color="inherit" onClick={handleDrawerToggle(true)}>
        <Menu />
      </IconButton>
    </Tooltip>
  );

  const stdMenuBtn = <HeadLinks clsrm={route.clsrm} mediumLs={true} />;

  return (
    <AppBar className={getBarClasses()}>
      <Toolbar className={classes.container}>
        {brandComponent}

        {/* "HeaderLinks" for desktop */}
        <div
          className={
            route.clsrm ? classes.showFor1600Up : classes.showFor1024Up
          }>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeadLinks clsrm={route.clsrm} />
          </div>
        </div>

        {/* Courses, MyStudy, Menu button for mobile */}
        <div
          className={
            route.clsrm ? classes.showFor1599Down : classes.showFor1023Down
          }>
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderCourses}
            {renderMyStudy}

            {/* Special logic caused by Classroom in Medium Landscape (1599-1024px) */}
            <div className={classes.showForLandscape}>
              {route.clsrm ? stdMenuBtn : hamburgerBtn}
              {/* {!route.clsrm && renderCartIcon()} */}
            </div>
            <div className={classes.hideForLandscape}>
              {/* {renderCartIcon()} */}
              {hamburgerBtn}
            </div>
          </div>
        </div>
      </Toolbar>

      {/* Drawer (holding HeaderLinks) for mobile */}
      <div
        className={
          route.clsrm ? classes.showFor1599Down : classes.showFor1023Down
        }>
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={s_mobileOpen}
          classes={{ paper: classes.drawerPaper }}
          onClose={handleDrawerToggle(false)}>
          <div
            className={classes.appResponsive}
            onClick={handleDrawerToggle(false)}
            onKeyDown={handleDrawerToggle(false)}>
            <HeadLinks clsrm={route.clsrm} />
          </div>
        </Drawer>
      </div>
    </AppBar>
  );
};

function mapStateToProps(state: any) {
  return {
    user: state.state_auth,
    // cart: selectorCart(state),
  };
}

export default withStyles(styles)(connect(mapStateToProps)(HeaderBase));
