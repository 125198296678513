import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";

import {
  // Apricot
  showFor1024Up,
  showFor1023Down,
  showFor1600Up,
  showFor1599Down,
} from "assets/jss/views/apricot.jsx";

const headerLinksStyle = ({ breakpoints, spacing, typography }: Theme) =>
  createStyles({
    list: {
      fontSize: "1rem",
      margin: 0,
      paddingLeft: 0,
      listStyle: "none",
      paddingTop: 0,
      paddingBottom: 0,
      color: "inherit",
    },
    listItem: {
      float: "left",
      color: "inherit",
      position: "relative",
      display: "block",
      width: "auto",
      margin: 0,
      padding: 0,
      [breakpoints.down("sm")]: {
        width: "100%",
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: "#e5e5e5",
        },
      },
    },

    navLink: {
      color: "inherit",
      position: "relative",
      padding: "0.9375rem",
      fontWeight: 400,
      fontSize: "1rem",
      textTransform: "uppercase",
      borderRadius: "3px",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex",
      "&:hover,&:focus": {
        color: "inherit",
        background: "rgba(200, 200, 200, 0.2)",
      },
      [breakpoints.down("sm")]: {
        width: "calc(100% - 30px)",
        marginLeft: "15px",
        marginBottom: "8px",
        marginTop: "8px",
        textAlign: "left",
        "& > span:first-child": {
          justifyContent: "flex-start",
        },
      },
    },

    dropdownIcons: {
      width: "24px",
      height: "24px",
      marginRight: "14px",
      opacity: "0.5",
      verticalAlign: "middle",
    },

    logoutIcon: {
      width: "24px",
      height: "24px",
      margin: "0 20px 0 5px",
      opacity: "0.5",
      verticalAlign: "middle",
    },

    apricadIcon: {
      width: "32px",
      height: "26px",
      margin: "-1px 12px 0 -4px",
    },

    dropdownLink: {
      "&,&:hover,&:focus": {
        color: "inherit",
        display: "block",
        padding: "10px 20px",
        margin: 0,
      },
      textTransform: "none",
      fontSize: "1rem",
      textAlign: "left",
    },

    textField: {
      marginLeft: spacing(1),
      marginRight: spacing(1),
      width: 160,
      marginTop: 0,
    },

    cartIcon: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },

    showFor1024Up,
    showFor1023Down,
    showFor1600Up,
    showFor1599Down,

    btnText: {
      textDecoration: "none",
    },
  });

export default headerLinksStyle;
