import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { List, ListItem, Divider } from "@material-ui/core";
// for Search
// import TextField from "@material-ui/core/TextField";
// import Search from "@material-ui/icons/Search";

// @material-ui/icons
import {
  Home,
  Apps,
  Menu,
  Face,
  LaptopMac,
  LocalCafe,
  Textsms,
} from "@material-ui/icons";

// CT UX
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button from "components/CustomButtons/Button";

import { getAllOwns, ApricotAction } from "client/actions/own";
import {
  sStart,
  sSupportUs,
  sGiveFeedback,
  sMyProfile,
  sMyStudy,
} from "assets/jss/views/ResStrings";
import {
  idMenu,
  idMyStudy,
  idSignin,
  idLogout,
  idCourses,
  idProfile,
  idSurvey,
  idSupportUs,
  idAbout,
} from "assets/jss/views/ElementIDs";

import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.tsx";
const styles = createStyles(headerLinksStyle as any);

// HeaderLinks component provides two things. In general,
// For large screen, show "Right part" of Header,
//    - before logged on: show LOGIN/SIGNUP button only
//    - after logged on:  show Main Menu (a dropdown)
// For small screen, show Hamburger menu items. Note that the MyStudy, Menu button, Cart are done in Header.tsx
//
// Classroom in smaller landscape (1599-1024px) is a special case. The Headers draws MyStudy,
// and the Main Menu is provided here.

interface Props extends WithStyles<typeof styles> {
  getAllOwns: ApricotAction;
  user: any;
  classes: any;
  clsrm: boolean;
  mediumLs?: boolean; // medium landscape case, for Classroom header on width 1599-1024px
}

type menuItemType = {
  name: string;
  path: string;
  href?: boolean; // item needs go thru browser, not just internal routing
  icon?: React.ReactNode;
  id?: string;
};

const HeaderLinksBase: React.FC<Props> = ({
  getAllOwns,
  user,
  classes,
  clsrm,
  mediumLs,
}: Props) => {
  // CAUTION: We should use Button/href ONLY when necessary (e.g. logout, which there's no 'page' in routes).
  // For others, use Link/to. Button/href causes page reloaded, thus re-fetch DB....

  // for Hamburger menu, not logged on
  const menuLoggedOff: menuItemType[] = [
    { name: "Home", path: "/" },
    { name: "Courses", path: "/cr_list" },
    { name: sGiveFeedback, path: "/sup/surv" },
    { name: sSupportUs, path: "/sup/supp" },
    { name: "About Us", path: "/lgl/about" },
    { name: sStart, path: "/signin" },
  ];

  // for both Main Menu and Hamburger when logged on
  const menuLoggedOn: menuItemType[] = [
    { name: "Home", path: "/", icon: Home },
    { name: "Courses", path: "/cr_list", id: idCourses, icon: Apps },
    { name: sMyProfile, path: "/prf/my", id: idProfile, icon: Face },
    { name: sGiveFeedback, path: "/sup/surv", id: idSurvey, icon: Textsms },
    { name: sSupportUs, path: "/sup/supp", id: idSupportUs, icon: LocalCafe },
    { name: "About Us", path: "/lgl/about", id: idAbout },
    { name: "Logout", path: "/prx/api/logout", id: idLogout, href: true },
  ];

  useEffect(() => {
    if (user) {
      // getCart(user._id);
      getAllOwns(user._id);
    } else {
      getAllOwns(null);
    }
  });

  // renderSearch(clsrm: boolean, classes: any) {
  //   if (clsrm) return "";

  //   return (
  //     <ListItem className={classes.listItem}>
  //       <div
  //         style={{
  //           width: "-webkit-fill-available",
  //           margin: "0 15px 0"
  //         }}
  //       >
  //         <TextField id="headerLinkSearch" label="Search" type="search" className={classes.textField} />
  //         <Button
  //           color="white"
  //           aria-label="edit"
  //           justIcon
  //           round
  //         >
  //           <Search />
  //         </Button>
  //       </div>
  //     </ListItem>
  //   );
  // }

  // For 1. Standard menu,  2. Hamburger menu when logged on
  const renderMenuItems = (largeScreen: boolean) => {
    var menuResult: React.ReactNode[] = [];
    menuLoggedOn.map((item: any) => {
      const TagName = item.icon;
      const btn = item.href ? ( // case like 'logout' goes thru Browser, cannot use <Link>
        <>
          {largeScreen && <Divider />}
          <a className={classes.dropdownLink} href={item.path}>
            <i
              className={classNames({
                [classes.logoutIcon]: true,
                ["fas fa-sign-out-alt"]: true, // Logout is the only case in this category
              })}
            />
            <span id={item.id}>{item.name}</span>
          </a>
        </>
      ) : (
        <>
          <Link className={classes.dropdownLink} to={item.path}>
            {TagName ? (
              <TagName className={classes.dropdownIcons} />
            ) : (
              <img
                src={require("assets/svg/apricot.svg")}
                className={classes.apricadIcon}
              />
            )}
            <span id={item.id}>{item.name}</span>
          </Link>
        </>
      );

      if (largeScreen) {
        // Standard menu
        menuResult.push(btn);
      } else {
        // Hamburger menu
        menuResult.push(
          <ListItem className={classes.listItem} key={item.path}>
            {btn}
          </ListItem>,
        );
      }
    });
    return menuResult;
  };

  const renderHamburgerMenu = () => {
    if (user) {
      return renderMenuItems(false);
    } else {
      var menuResult: React.ReactNode[] = [];
      menuLoggedOff.map((item) => {
        menuResult.push(
          <ListItem className={classes.listItem} key={item.path}>
            <Button color="transparent" className={classes.dropdownLink}>
              <Link style={{ color: "inherit" }} to={item.path}>
                {item.name}
              </Link>
            </Button>
          </ListItem>,
        );
      });
      return menuResult;
    }
  };

  const renderDropdownMenu = () =>
    user ? (
      <ListItem className={classes.listItem} style={{ padding: 0 }}>
        <CustomDropdown
          buttonId={idMenu}
          noLiPadding
          buttonText={"Menu"}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Menu}
          dropdownList={renderMenuItems(true)}
        />
      </ListItem>
    ) : (
      <></>
    );

  const renderHeaderItem = (props: {
    mustLogin?: boolean;
    link: string;
    label: string;
    id: string;
    icon: React.ReactElement;
  }) => {
    const { mustLogin = false, link, label, id, icon } = props;
    return mustLogin && !user ? (
      ""
    ) : (
      <ListItem className={classes.listItem}>
        <Link style={{ color: "inherit" }} to={link}>
          <Button color="transparent" className={classes.navLink}>
            <span style={{ position: "relative", bottom: "1px" }}>{icon}</span>
            <span id={id}>{label}</span>
          </Button>
        </Link>
      </ListItem>
    );
  };

  // const renderCartIcon = () => {
  //   const numItems = cart !== null ? cart.items.length : 0;
  //   const numBadge = (
  //     <Tooltip title={"Shopping Cart"} placement="bottom">
  //       <Badge
  //         classes={{ badge: classes.cartIcon }}
  //         badgeContent={numItems}
  //         color="error">
  //         <ShoppingCart />
  //       </Badge>
  //     </Tooltip>
  //   );

  //   return (user && (
  //       <ListItem className={classes.listItem}>
  //         <Link to="/shp/cart" className={classes.navLink}>{numBadge}</Link>
  //       </ListItem>
  //     ));
  // };

  const renderAuthButton = () =>
    user ? (
      <></>
    ) : (
      <ListItem className={classes.listItem}>
        <Link style={{ color: "inherit" }} to="/signin">
          <Button color="transparent" className={classes.navLink}>
            <span id={idSignin}>{sStart}</span>
          </Button>
        </Link>
      </ListItem>
    );

  return clsrm && mediumLs ? (
    <List className={classes.list}>
      {user ? renderDropdownMenu() : renderAuthButton()}
    </List>
  ) : (
    <>
      {/* Right parts of Header, for larger screen */}
      <div className={classes.showFor1024Up}>
        <List className={classes.list}>
          {/* {renderSearch(clsrm, classes)} */}
          {renderHeaderItem({
            label: "Courses",
            link: "/cr_list",
            id: idCourses,
            icon: <Apps />,
          })}
          {renderHeaderItem({
            label: sMyStudy,
            link: "/mys",
            id: idMyStudy,
            icon: <LaptopMac />,
          })}
          {renderDropdownMenu()}
          {/* {renderCartIcon()} */}
          {renderAuthButton()}
        </List>
      </div>

      {/* Hamburger Menu content for mobile */}
      <div className={classes.showFor1023Down}>
        <List className={classes.list}>
          {/* {renderSearch(clsrm, classes)} */}
          {renderHamburgerMenu()}
        </List>
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    user: state.state_auth,
    // cart: selectorCart(state),
  };
}

const base: any = HeaderLinksBase;
export const HeaderLinks: any = withStyles(headerLinksStyle, {
  withTheme: true,
})(connect(mapStateToProps, { getAllOwns })(base));

export const HeaderLinksTest = withStyles(headerLinksStyle, {
  withTheme: true,
})(base);
